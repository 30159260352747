import React, { useState, useEffect, useRef } from 'react';
import './Header.css';
import { ReactComponent as Logo } from '../Logo_srs.svg';
import { ReactComponent as CartIcon } from '../cart.svg';
import { ReactComponent as AvatarIcon } from '../avatar.svg';
import { ReactComponent as SettingsIcon } from '../settings.svg';
import { ReactComponent as OrdersIcon } from '../orders.svg';
import { ReactComponent as LogoutIcon } from '../logout.svg';
import { ReactComponent as LoginIcon } from '../login.svg';
import AuthPopup from './AuthPopup';

const Header = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchHistory, setSearchHistory] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showAuthPopup, setShowAuthPopup] = useState(false);
    const searchBarRef = useRef(null);
    const historyRef = useRef(null);
    const overlayRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        setUserLoggedIn(!!token);

        const history = JSON.parse(localStorage.getItem('searchHistory')) || [];
        setSearchHistory(history.reverse());

        const savedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
        setCartItems(savedCart);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchBarRef.current &&
                !searchBarRef.current.contains(event.target) &&
                historyRef.current &&
                !historyRef.current.contains(event.target)
            ) {
                setShowHistory(false);
                if (overlayRef.current) {
                    overlayRef.current.classList.remove('show');
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (showHistory) {
            if (overlayRef.current && searchHistory.length > 0) {
                overlayRef.current.classList.add('show');
            }
        } else {
            if (overlayRef.current) {
                overlayRef.current.classList.remove('show');
            }
        }
    }, [showHistory, searchHistory]);

    const handleSearch = (term) => {
        const searchQuery = term || searchTerm;
        if (searchQuery) {
            const updatedHistory = [searchQuery, ...searchHistory];
            setSearchHistory(updatedHistory);
            localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
            setSearchTerm('');
            setShowHistory(false);
        }
    };

    const clearHistory = () => {
        localStorage.removeItem('searchHistory');
        setSearchHistory([]);
        setShowHistory(false);
    };

    const removeSearchTerm = (termToRemove) => {
        const updatedHistory = searchHistory.filter(term => term !== termToRemove);
        setSearchHistory(updatedHistory);
        localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
        if (updatedHistory.length === 0) {
            setShowHistory(false);
        }
    };

    const handleInputClick = () => {
        setShowHistory(true);
    };

    const handleHistoryItemClick = (term) => {
        setSearchTerm(term);
        handleSearch(term);
    };

    const handleHistoryItemHover = (term) => {
        setSearchTerm(term);
        document.querySelector('.search-input').classList.add('gray-placeholder');
    };

    const handleMouseOut = () => {
        setSearchTerm('');
        document.querySelector('.search-input').classList.remove('gray-placeholder');
    };

    const handleLogin = () => {
        setShowAuthPopup(true);
    };

    // Функция выхода из системы
    const logoutUser = () => {
        localStorage.removeItem('jwtToken');
        setUserLoggedIn(false);
    };

    const handleLogout = () => {
        logoutUser();
    };

    return (
        <header className="header">
            <div className="logo-container">
                <a href="/" className="logo-link">
                    <Logo className="logo" />
                </a>
            </div>
            <div className="header-right">
                <a href="/catalog/" className="catalog-link">Каталог</a>
                <div className="search-bar-container">
                    <div className="search-bar" ref={searchBarRef}>
                        <form className="search-history-form" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onClick={handleInputClick}
                                placeholder="футболка"
                                className="search-input"
                            />
                            <button type="submit" className="search-button">Найти</button>
                            {searchHistory.length > 0 && showHistory && (
                                <div className={`search-history ${showHistory ? 'show' : ''}`} ref={historyRef}>
                                    <div className="search-history-head">
                                        <div className="search-history-head-text">История поиска</div>
                                        <button onClick={clearHistory} className="clear-history-button">Очистить всю историю</button>
                                    </div>
                                    <div className="search-history-content">
                                        {searchHistory.map((term, index) => (
                                            <div
                                                key={index}
                                                className="search-history-item"
                                                onClick={() => handleHistoryItemClick(term)}
                                                onMouseOver={() => handleHistoryItemHover(term)}
                                                onMouseOut={handleMouseOut}
                                            >
                                                {term}
                                                <button
                                                    className="remove-history-item"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeSearchTerm(term);
                                                    }}
                                                >
                                                    ✖
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
                <a href="/cart/" className="cart">
                    <CartIcon className="cart-icon" />
                    {cartItems.length > 0 && <div className="cart-amount">{cartItems.length}</div>}
                </a>
                <div className="avatar" onClick={() => setShowUserMenu(!showUserMenu)}>
                    <AvatarIcon className="avatar-icon" />
                    {userLoggedIn ? (
                        <div className={`user-menu ${showUserMenu ? 'show' : ''}`}>
                            <div className="user-menu-item">
                                <SettingsIcon className="user-menu-icon" />
                                <span>Настройки</span>
                            </div>
                            <div className="user-menu-item">
                                <OrdersIcon className="user-menu-icon" />
                                <span>Заказы</span>
                            </div>
                            <div className="user-menu-item" onClick={handleLogout}>
                                <LogoutIcon className="user-menu-icon" />
                                <span>Выйти из аккаунта</span>
                            </div>
                        </div>
                    ) : (
                        <div className={`user-menu ${showUserMenu ? 'show' : ''}`}>
                            <div className="user-menu-item" onClick={handleLogin}>
                                <LoginIcon className="user-menu-icon" />
                                <span>Войти</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="search-overlay" ref={overlayRef}></div>
            {showAuthPopup && <AuthPopup onClose={() => setShowAuthPopup(false)} />}
        </header>
    );
};

export default Header;