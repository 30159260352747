import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Products from './pages/Products';
import Account from './pages/Account';
import Catalog from './pages/Catalog';
import NotFound from './pages/NotFound';
import CartPage from './pages/CartPage'; // Добавляем страницу корзины
import ProductPage from './pages/ProductPage'; // Добавляем страницу товара
import AdminPage from './pages/AdminPage';
import './App.css';

const RedirectToCorrectPath = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const path = location.pathname;
        const normalizedPath = path
            .replace(/\/{2,}/g, '/') // Удаляем лишние слэши
            .toLowerCase();         // Приводим к нижнему регистру

        if (path !== normalizedPath) {
            navigate(normalizedPath, { replace: true });
        }
    }, [location, navigate]);

    return null;
};

function App() {
    return (
        <Router>
            <RedirectToCorrectPath />
            <div>
                <Header />
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/catalog" element={<Catalog />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/account" element={<Account />} />
                        <Route path="/cart" element={<CartPage />} />
                        <Route path="/product/:id" element={<ProductPage />} />
                        <Route path="/admin" element={<AdminPage />} /> {/* Добавляем маршрут админки */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;