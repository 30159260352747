import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProductPage = ({ product }) => {
    const navigate = useNavigate();

    const addToCart = () => {
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        cartItems.push(product);
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        navigate('/cart');
    };

    return (
        <div className="product-page">
            <h1>{product.name}</h1>
            <button onClick={addToCart}>Добавить в корзину</button>
        </div>
    );
};

export default ProductPage;