import React from 'react';
import TrafficChart from '../components/TrafficChart'; // Путь к компоненту с графиком

function AdminPage() {
    return (
        <div>
            <h1>Админ панель</h1>
            <TrafficChart /> {/* Вставляем график на страницу */}
        </div>
    );
}

export default AdminPage;
