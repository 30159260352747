import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function TrafficChart() {
    const [trafficData, setTrafficData] = useState([]);

    useEffect(() => {
        // Получаем данные с API
        fetch('/api/traffic')
            .then(response => response.json())
            .then(data => setTrafficData(data));
    }, []);

    const chartData = {
        labels: trafficData.map(item => item.url), // Строки для оси X
        datasets: [{
            label: 'Количество запросов',
            data: trafficData.map(item => item.count), // Данные для оси Y
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.2)',
        }],
    };

    return (
        <div>
            <h3>График трафика</h3>
            <Line data={chartData} />
        </div>
    );
}

export default TrafficChart;
